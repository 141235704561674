import { useRef, Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import ArrowRightIcon from 'components/Icons/ArrowRight';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from 'components/Container/Container';
import PublicationStream from 'components/Publications/StreamHome';
import makeStyles from '@mui/styles/makeStyles';
import { load as loadStories } from 'redux/modules/stories';
import { useDispatch, useSelector } from 'react-redux';
import getImageLink from 'utils/getImageLink';
import { Link, useLocation } from 'react-router-dom';
import dateFormat from 'date-fns/format';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Sticky from 'react-stickynode';
import PlaceHolderLoading from 'components/Publications/PlaceholderLoading';
import VisibilitySensor from 'components/VisibilitySensor/VisibilitySensor';
import useWidth from 'hooks/useWidth';
import getLink from 'utils/getLink';
import Adsense from 'components/Adsense/Adsense';
import TrendingIcon from 'components/Icons/Trending';
import config from 'config';
import MiddotDivider from 'components/MiddotDivider/MiddotDivider';
import { useIntl } from 'react-intl';
import useLocale from 'hooks/useLocale';

const { domain } = config;

const getImage = (image: any, version: any) => {
  if (image && image.md5) return getImageLink(image.md5, version, image.format);
  return false;
};

const useStylesM = makeStyles(
  (theme) => ({
    containerGrey: {
      background: theme.palette.mode === 'light' ? '#FFFFFF' : theme.palette.grey[900],
      padding: 15,
    },

    containerWhite: {
      background: theme.palette.mode === 'light' ? '#FFFFFF' : theme.palette.grey[900],
      padding: 15,
    },

    containerSelectable: {
      cursor: 'pointer',
    },

    button: {
      margin: theme.spacing(1),
    },

    paperRoot: {
      marginTop: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },

    separator: {
      width: '100%',
      borderBottom: '1px solid #dcdcdc',
      marginTop: 15,
      marginBottom: 10,
    },

    center: {
      textAlign: 'center',
    },

    containerContentHome: {
      overflow: 'hidden',
      marginTop: 20,
    },

    commentsContainer: {
      height: 220,
    },

    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },

    root: {
      overflow: 'hidden',
    },

    popularNewsContainerOl: {
      padding: 32,
      margin: 0,
      listStyle: 'none',
      listStyleImage: 'none',
      lineHeight: '20px',
      fontSize: 16,
    },

    popularLiItem: {
      marginBottom: 24,
      display: 'flex',
    },

    popularIndex: {
      marginTop: 4,
      fontSize: 34,
      color: 'rgba(0,0,0,.15)',
      fill: 'rgba(0,0,0,.15)',
    },

    popularContent: {
      minWidth: 200,
      marginLeft: 16,
      webkitBoxFlex: 1,
      '-webkit-flex': '1 1 auto',
      '-ms-flex': '1 1 auto',
      flex: '1 1 auto',
    },

    popularNewsTitle: {
      margin: 0,
    },

    popularNews: {
      marginLeft: 32,
      marginRight: 32,
      paddingTop: 32,
      borderBottom:
        theme.palette.mode === 'light'
          ? '1px solid rgba(0,0,0,.15)'
          : '1px solid rgba(255,255,255,.15)',
      paddingBottom: 16,
      fontSize: 24,
    },

    popularNewsPlaceholder: {
      borderBottom:
        theme.palette.mode === 'light'
          ? '1px solid rgba(0,0,0,.05)'
          : '1px solid rgba(255,255,255,.15)',
    },

    footer: {
      paddingLeft: 16,
      paddingRight: 16,
      marginTop: 30,
    },

    footerContainer: {
      borderTop: '1px solid rgba(0,0,0,.05)',
      paddingTop: 10,
      paddingBottom: 25,
    },

    linksFooter: {
      marginRight: 16,
      fontSize: 16,
      color: 'rgba(0,0,0,.54)',
      display: 'inline-block',
    },

    '@media screen and (max-width: 767px)': {
      popularNewsContainerOl: {
        padding: 0,
        paddingTop: 32,
      },
      popularNews: {
        margin: 0,
        paddingTop: 0,
      },
      footer: {
        padding: 0,
      },
    },

    noVisible: {
      visibility: 'hidden',
    },

    placeHolderLoader: {
      marginBottom: theme.spacing(4),
    },

    dividerSections: {
      position: 'relative',
      borderBottom: '1px solid rgba(0,0,0,.15)',
      maxWidth: 1000,
      margin: '24px auto',
    },

    containerAdsenseTop: {
      maxWidth: 1000,
      margin: '0 auto',
      textAlign: 'center',
    },
    containerAdsenseTopTop: {
      maxWidth: 1000,
      width: '100%',
      height: 90,
      margin: '0 auto',
      textAlign: 'center',
    },
    containerAdsenseSidebar: {
      width: '100%',
      minHeight: 250,
      maxHeight: 350,
      height: '100%',
    },
    [theme.breakpoints.only('xs')]: {
      containerAdsenseTopTop: {
        height: 100,
      },
    },

    inFeedAdsense: {
      // paddingLeft: '0!important',
      paddingRight: '0!important',
    },

    alignRight: {
      textAlign: 'right',
    },
  }),
  { name: 'WMHome' }
);

const privacyPolicyUrls = {
  es: '/policy/politica-de-privacidad-de-webmediums-nkgyas2objom',
  en: '/policy/webmediums-privacy-policy-yqn96zx5j9h6',
};
const aboutUsUrls = {
  es: '/webmediums-espanol/quienes-somos-dek29jjtd1xy',
  en: '/webmediums-english/aabout-us-zgz95uphg6it',
};

function WithWidth(props: any) {
  const { classes, popular, intl, dateFns, location } = props; // eslint-disable-line
  const width = useWidth();
  return (
    <Sticky enabled={width !== 'xs'} top={52}>
      {!popular || popular.length === 0 ? (
        <>
          <Typography
            className={[classes.popularNews, classes.popularNewsPlaceholder, 'clearfix'].join(' ')}
            component="div"
          >
            {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
            <PlaceHolderLoading size="title" />
          </Typography>
          <ol className={classes.popularNewsContainerOl}>
            <li>
              {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
              <PlaceHolderLoading size="small" withContent={false} useSeparator={false} />
            </li>
            <li>
              {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
              <PlaceHolderLoading size="small" withContent={false} useSeparator={false} />
            </li>
            <li>
              {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
              <PlaceHolderLoading size="small" withContent={false} useSeparator={false} />
            </li>
          </ol>
        </>
      ) : (
        <div>
          <Typography className={classes.popularNews} variant="h3">
            {intl.formatMessage({
              id: 'popularStories',
              defaultMessage: 'Popular stories',
            })}
          </Typography>
          <ol className={classes.popularNewsContainerOl}>
            {popular.map((post: any, index: any) => {
              const minutes = post.readingTime ? post.readingTime / 60 / 1000 : 0;
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              const readingTime = Math.ceil(minutes.toFixed(2));
              const newsLink = getLink(post);
              return (
                <li key={`popular-li-${post.uid}`} className={classes.popularLiItem}>
                  <div className={classes.popularIndex}>0{index + 1}</div>
                  <div className={classes.popularContent}>
                    <Link to={newsLink}>
                      <Typography variant="h3" className={classes.popularNewsTitle}>
                        {post.title}
                      </Typography>
                    </Link>
                    <Typography variant="caption" className={classes.listMetas}>
                      <time dateTime={post.publishDate}>
                        {dateFormat(new Date(post.publishDate), 'MMM d', {
                          locale: dateFns,
                        })}
                      </time>
                      <MiddotDivider />
                      <span>
                        {intl.formatMessage(
                          {
                            id: 'readingTime',
                            defaultMessage: '{readingTime} min read',
                          },
                          {
                            readingTime,
                          }
                        )}
                      </span>
                    </Typography>
                  </div>
                </li>
              );
            })}
          </ol>
        </div>
      )}
      <footer className={classes.footer}>
        <div className={classes.footerContainer}>
          {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
          <Link className={classes.linksFooter} to={privacyPolicyUrls[intl.locale]}>
            {intl.formatMessage({
              id: 'privacy',
              defaultMessage: 'Privacy',
            })}
          </Link>
          {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
          <Link className={classes.linksFooter} to={privacyPolicyUrls[intl.locale]}>
            {intl.formatMessage({
              id: 'terms',
              defaultMessage: 'Terms',
            })}
          </Link>
          {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
          <Link className={classes.linksFooter} to={aboutUsUrls[intl.locale]}>
            {intl.formatMessage({
              id: 'about',
              defaultMessage: 'About',
            })}
          </Link>
          {/* <Link className={classes.linksFooter} to="/coronavirus">
            Coronavirus Live Updates
          </Link> */}
          <a className={classes.linksFooter} target="_bank" href="https://twitter.com/@WebMediums">
            Twitter
          </a>
          <a className={classes.linksFooter} target="_bank" href="https://facebook.com/WebMediums">
            Facebook
          </a>
          <a
            className={classes.linksFooter}
            target="_bank"
            href="https://www.instagram.com/WebMediums/"
          >
            Instagram
          </a>
          <a
            className={classes.linksFooter}
            target="_bank"
            href="https://www.youtube.com/channel/UCn6bzBlB7Hnm3IbC7rHZ_Eg"
          >
            Youtube
          </a>
          <Link className={classes.linksFooter} to="/espanol">
            Español
          </Link>
          <Link className={classes.linksFooter} to="/english">
            English
          </Link>
        </div>
      </footer>
      <Adsense
        style={{ display: 'inline-block' }}
        format=""
        lazyLoad
        className={classes.containerAdsenseSidebar}
        key={`${location.key}-adsense-home-top-top`}
        responsive="false"
        client="ca-pub-9169771209644523"
        slot="9044658145"
      />
    </Sticky>
  );
}

const Sidebar = WithWidth;

let timeoutLoadMore: any = null;

const Home = () => {
  const skip = useRef(0);
  const intl = useIntl();
  const localeContext = useLocale();
  const dispatch = useDispatch();
  const classes = useStylesM();

  const { dateFns } = localeContext;

  const { stories, hasMoreByKey, popular, loading } = useSelector((state) => ({
    stories: (state as any).stories && (state as any).stories.stories,
    hasMoreByKey: (state as any).stories && (state as any).stories.hasMoreByKey,
    popular: (state as any).stories && (state as any).stories.popular,
    loadingPopular: (state as any).stories && (state as any).stories.loadingPopularStories,
    loading: (state as any).stories && (state as any).stories.loading,
  }));

  const location = useLocation();

  const pathsToLang: any = {
    '/english': 'en',
    '/espanol': 'es',
  };

  const lang = pathsToLang[location.pathname];

  const renderPlaceHolderList = (limit: any, size = 'normal') => {
    if (limit === 1) {
      return (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
            <PlaceHolderLoading useSeparator={false} size={size} type="stream" />
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <PlaceHolderLoading useSeparator={false} size={size} type="stream" />
        </Grid>
        <Grid item xs={12}>
          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <PlaceHolderLoading useSeparator={false} size={size} type="stream" />
        </Grid>
        <Grid item xs={12}>
          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <PlaceHolderLoading useSeparator={false} size={size} type="stream" />
        </Grid>
        <Grid item xs={12}>
          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <PlaceHolderLoading useSeparator={false} size={size} type="stream" />
        </Grid>
      </Grid>
    );
  };

  const loadMore = () => {
    if (timeoutLoadMore) {
      clearTimeout(timeoutLoadMore);
    }
    timeoutLoadMore = setTimeout(() => {
      const paramsSearch: any = {
        status: 'public',
        $skip: 0,
        $excludeTopStoriesHome: true,
        isFeatured: 0,
        $limit: 20,
        $paginate: false,
        $exclude: ['content'],
        $sort: {
          publishDate: -1,
        },
      };

      if (lang) {
        (paramsSearch as any).locale = lang;
      } else {
        paramsSearch.$distinct = true;
      }

      const key = JSON.stringify(paramsSearch);

      paramsSearch.$limit = 10;
      skip.current += skip.current === 0 ? 20 : 10;
      paramsSearch.$skip = skip.current;
      // paramsSearch.$skip += 10;
      dispatch(loadStories(paramsSearch, key));
    }, 50);
  };

  const paramsSearchTop: any = {
    status: 'public',
    $skip: 0,
    isFeatured: 0,
    $limit: 6,
    $paginate: false,
    $exclude: ['content'],
    'Publication.slug': [
      'actualidad',
      'news',
      'deportes',
      'sports',
      'salud',
      'health',
      'apple-universe',
      'universo-apple',
    ],
    $sort: {
      publishDate: -1,
    },
  };

  const paramsSearch = {
    status: 'public',
    $skip: 0,
    $excludeTopStoriesHome: true,
    isFeatured: 0,
    $limit: 20,
    $paginate: false,
    $exclude: ['content'],
    $sort: {
      publishDate: -1,
    },
  };

  if (lang) {
    (paramsSearch as any).locale = lang;
    (paramsSearchTop as any).locale = lang;
  } else {
    paramsSearch.$distinct = true;
    paramsSearchTop.$distinct = true;
  }

  const keyTop = JSON.stringify(paramsSearchTop);
  const key = JSON.stringify(paramsSearch);

  const hasMore = hasMoreByKey && hasMoreByKey[key];
  const baseDate = new Date();
  const storiesTop = stories[keyTop];
  const storiesCurrent = stories[key];
  const storiesFeaturedHome = stories[`featuredHome_${lang || ''}`];

  const spacing = 5;
  const titlesLangs = {
    '': 'WebMediums – read news, politics, stories, stay informed',
    en: 'WebMediums – English',
    es: 'WebMediums – Español',
  };

  const canonicaFeddlLinkLangs = {
    '': '/feed/latest',
    en: '/feed/latest/en',
    es: '/feed/latest/es',
  };

  const canonicalHomeLangs = {
    '': '/',
    en: '/english',
    es: '/espanol',
  };
  const featuredUrls = {
    en: '/topic/editors-picks',
    es: '/tema/seleccion-editores',
  };

  // @ts-expect-error ts-migrate(2538) FIXME: Type 'true' cannot be used as an index type.
  const title = titlesLangs[lang || ''];
  const description =
    // eslint-disable-next-line
    'Welcome to WebMediums. WebMediums searches, analyzes and tells you stories and events from day to day, historical and with a critical point of view and without manipulation, only the truth behind our lines.';
  // @ts-expect-error ts-migrate(2538) FIXME: Type 'true' cannot be used as an index type.
  const canonicaFeddlLink = `${domain}${canonicaFeddlLinkLangs[lang || '']}`;

  const ldjson = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: config.domain,
    name: config.app.title,
    alternateName:
      // eslint-disable-next-line
      'WebMediums searches, analyzes and tells you stories and events from day to day, historical and with a critical point of view and without manipulation, only the truth behind our lines.',
    image: 'https://webmediums.com/media/max_1600/1*NaXapbTluUAlP1Xmqib5mQ.png',
    sameAs: [
      'https://www.facebook.com/WebMediums',
      'https://instagram.com/WebMediums',
      'https://www.linkedin.com/company/WebMediums/',
      'https://twitter.com/@WebMediums',
    ],
  };

  const ldjsonOrg = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    duns: '087485733',
    name: config.app.title,
    legalName: 'WebMediums CORP',
    email: 'mailto:contact@webmediums.com',
    url: config.domain,
    logo: 'https://webmediums.com/media/max_1600/1*NaXapbTluUAlP1Xmqib5mQ.png',
    sameAs: [
      'https://www.facebook.com/WebMediums',
      'https://instagram.com/WebMediums',
      'https://www.linkedin.com/company/WebMediums/',
      'https://twitter.com/@WebMediums',
    ],
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
        {/* @ts-expect-error ts-migrate(2538) FIXME: Type 'true' cannot be used as an index type. */}
        <link rel="canonical" href={`https://webmediums.com${canonicalHomeLangs[lang || '']}`} />
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="twitter:title" content={title} />
        <meta name="og:description" content={description} />
        <meta name="twitter:description" content={description} />
        <meta property="og:type" content="website" />
        <meta name="robots" content="index, follow" />
        <link rel="alternate" type="application/rss+xml" title="RSS" href={canonicaFeddlLink} />
        <script type="application/ld+json">{JSON.stringify(ldjson)}</script>
        <script type="application/ld+json">{JSON.stringify(ldjsonOrg)}</script>
      </Helmet>

      <Container padding="Home" size="publicationSize">
        <Box sx={{ mr: 1, display: 'inline-flex', alignItems: 'center' }}>
          <Box sx={{ mr: 1, display: 'inline-flex' }}>
            <TrendingIcon />
          </Box>
          <Typography
            sx={{
              display: 'inline-flex',
              textTransform: 'uppercase',
              letterSpacing: '0.003em',
              fontSize: 12,
              fontWeight: 700,
              color: 'rgba(41, 41, 41, 1)',
              lineHeight: '16px',
            }}
            fontFamily={'sohne, "Helvetica Neue", Helvetica, Arial, sans-serif'}
          >
            {intl.formatMessage({
              id: 'Latest_News',
              defaultMessage: 'Latest News',
            })}
          </Typography>
        </Box>
        <Grid container spacing={1} sx={{ mb: 2 }}>
          {!storiesTop &&
            [1, 2, 3, 4, 5, 6].map((number) => {
              return (
                <Grid key={`placeholder-top${number}`} item xs={12} sm={6} md={4}>
                  {renderPlaceHolderList(1, 'small')}
                </Grid>
              );
            })}
          {!!storiesTop &&
            storiesTop.map((story) => {
              return (
                <Grid key={`story-top-${story.uid}`} item xs={12} sm={6} md={4}>
                  <PublicationStream
                    post={story}
                    image={getImage(story.featureImg, 'focal_280x240')}
                    imageWEBP={getImage(story.featureImg, 'focal_280x240')}
                    withImage
                    size="small"
                    imagePosition="left"
                    title={story.title}
                    subtitle={story.subtitle}
                    author={story.User}
                    publication={story.Publication}
                    baseDate={baseDate}
                    date={story.publishDate}
                    readTime={Math.ceil(story.readingTime / 1000 / 60)}
                    imageLink={getLink(story)}
                    titleLink={getLink(story)}
                  />{' '}
                </Grid>
              );
            })}
          <Grid item xs={12}>
            <Adsense
              style={{ display: 'inline-block' }}
              format=""
              className={classes.containerAdsenseTopTop}
              key={`${location.key}-adsense-home-top-top`}
              responsive="false"
              client="ca-pub-9169771209644523"
              slot="6649594940"
            />
          </Grid>
        </Grid>
      </Container>
      <div className={classes.dividerSections} />
      <Container padding="Home" size="publicationSize">
        <Box sx={{ mr: 1, display: 'inline-flex', alignItems: 'center' }}>
          <Box sx={{ mr: 1, display: 'inline-flex' }}>
            <TrendingIcon />
          </Box>
          <Typography
            sx={{
              display: 'inline-flex',
              textTransform: 'uppercase',
              letterSpacing: '0.003em',
              fontSize: 12,
              fontWeight: 700,
              color: 'rgba(41, 41, 41, 1)',
              lineHeight: '16px',
            }}
            fontFamily={'sohne, "Helvetica Neue", Helvetica, Arial, sans-serif'}
          >
            {intl.formatMessage({
              id: 'Featured',
              defaultMessage: 'Featured',
            })}
          </Typography>
        </Box>
      </Container>
      {(!storiesFeaturedHome || !storiesFeaturedHome[0]) && (
        // @ts-expect-error ts-migrate(2739) FIXME: Type '{ children: Element; key: string; size: "ext... Remove this comment to see the full error message
        <Container key="content-first-home-loading" size="extremeSize" padding="Home" spacing={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={5}>
              {renderPlaceHolderList(1, 'small')}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {renderPlaceHolderList(1, 'small')}
                </Grid>
                <Grid item xs={12}>
                  {renderPlaceHolderList(1, 'small')}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              {renderPlaceHolderList(1, 'small')}
            </Grid>
          </Grid>
        </Container>
      )}
      {!!storiesFeaturedHome && storiesFeaturedHome[0] && (
        // @ts-expect-error ts-migrate(2739) FIXME: Type '{ children: Element; key: string; size: "ext... Remove this comment to see the full error message
        <Container key="content-first-home" size="extremeSize" padding="Home" spacing={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={5}>
              <PublicationStream
                post={storiesFeaturedHome[0]}
                image={getImage(storiesFeaturedHome[0].featureImg, 'focal_832x302')}
                imageWEBP={getImage(storiesFeaturedHome[0].featureImg, 'webp_focal_832x302')}
                withImage
                size="large"
                titleEl="h1"
                imagePosition="left"
                title={storiesFeaturedHome[0].title}
                subtitle={storiesFeaturedHome[0].subtitle}
                author={storiesFeaturedHome[0].User}
                publication={storiesFeaturedHome[0].Publication}
                baseDate={baseDate}
                date={storiesFeaturedHome[0].publishDate}
                readTime={Math.ceil(storiesFeaturedHome[0].readingTime / 1000 / 60)}
                imageLink={getLink(storiesFeaturedHome[0])}
                titleLink={getLink(storiesFeaturedHome[0])}
              />
            </Grid>
            {storiesFeaturedHome[1] && (
              <Grid item xs={12} sm={6} md={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <PublicationStream
                      subtitleNoWrap
                      post={storiesFeaturedHome[1]}
                      image={getImage(storiesFeaturedHome[1].featureImg, 'focal_200x200')}
                      imageWEBP={getImage(storiesFeaturedHome[1].featureImg, 'webp_focal_200x200')}
                      withImage
                      size="small"
                      imagePosition="left"
                      title={storiesFeaturedHome[1].title}
                      subtitle={storiesFeaturedHome[1].subtitle}
                      author={storiesFeaturedHome[1].User}
                      publication={storiesFeaturedHome[1].Publication}
                      baseDate={baseDate}
                      date={storiesFeaturedHome[1].publishDate}
                      readTime={Math.ceil(storiesFeaturedHome[1].readingTime / 1000 / 60)}
                      imageLink={getLink(storiesFeaturedHome[1])}
                      titleLink={getLink(storiesFeaturedHome[1])}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {storiesFeaturedHome[2] && (
                      <PublicationStream
                        subtitleNoWrap
                        post={storiesFeaturedHome[2]}
                        image={getImage(storiesFeaturedHome[2].featureImg, 'focal_200x200')}
                        imageWEBP={getImage(
                          storiesFeaturedHome[2].featureImg,
                          'webp_focal_200x200'
                        )}
                        withImage
                        size="small"
                        imagePosition="left"
                        title={storiesFeaturedHome[2].title}
                        subtitle={storiesFeaturedHome[2].subtitle}
                        author={storiesFeaturedHome[2].User}
                        publication={storiesFeaturedHome[2].Publication}
                        baseDate={baseDate}
                        date={storiesFeaturedHome[2].publishDate}
                        readTime={Math.ceil(storiesFeaturedHome[2].readingTime / 1000 / 60)}
                        imageLink={getLink(storiesFeaturedHome[2])}
                        titleLink={getLink(storiesFeaturedHome[2])}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {storiesFeaturedHome[3] && (
                      <PublicationStream
                        subtitleNoWrap
                        post={storiesFeaturedHome[3]}
                        image={getImage(storiesFeaturedHome[3].featureImg, 'focal_200x200')}
                        imageWEBP={getImage(
                          storiesFeaturedHome[3].featureImg,
                          'webp_focal_200x200'
                        )}
                        withImage
                        size="small"
                        imagePosition="left"
                        title={storiesFeaturedHome[3].title}
                        subtitle={storiesFeaturedHome[3].subtitle}
                        author={storiesFeaturedHome[3].User}
                        publication={storiesFeaturedHome[3].Publication}
                        baseDate={baseDate}
                        date={storiesFeaturedHome[3].publishDate}
                        readTime={Math.ceil(storiesFeaturedHome[3].readingTime / 1000 / 60)}
                        imageLink={getLink(storiesFeaturedHome[3])}
                        titleLink={getLink(storiesFeaturedHome[3])}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={3}>
              {storiesFeaturedHome[4] && (
                <PublicationStream
                  post={storiesFeaturedHome[4]}
                  image={getImage(storiesFeaturedHome[4].featureImg, 'focal_1080x444')}
                  imageWEBP={getImage(storiesFeaturedHome[4].featureImg, 'webp_focal_1080x444')}
                  withImage
                  size="medium"
                  imagePosition="left"
                  title={storiesFeaturedHome[4].title}
                  subtitle={storiesFeaturedHome[4].subtitle}
                  author={storiesFeaturedHome[4].User}
                  publication={storiesFeaturedHome[4].Publication}
                  baseDate={baseDate}
                  date={storiesFeaturedHome[4].publishDate}
                  readTime={Math.ceil(storiesFeaturedHome[4].readingTime / 1000 / 60)}
                  imageLink={getLink(storiesFeaturedHome[4])}
                  titleLink={getLink(storiesFeaturedHome[4])}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      )}
      {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ children: Element; className: any; key: st... Remove this comment to see the full error message */}
      <Container
        className={classes.alignRight}
        key="container-featuredlink-home"
        padding="Home"
        size="publicationSize"
      >
        <Button
          component={Link}
          to={featuredUrls[lang] || '/topic/editors-picks'}
          color="secondary"
        >
          {intl.formatMessage({
            id: 'see_all_featured',
            defaultMessage: 'SEE ALL FEATURED',
          })}{' '}
          <ArrowRightIcon />
        </Button>
      </Container>
      <div className={classes.dividerSections} />
      <div className={classes.containerAdsenseTop}>
        <Adsense
          key={`${location.key}-adsense-home-top`}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; responsive: string; client: s... Remove this comment to see the full error message
          responsive="true"
          client="ca-pub-9169771209644523"
          slot="9381230149"
        />
      </div>
      <div className={classes.dividerSections} />
      {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ children: Element; key: string; size: "pub... Remove this comment to see the full error message */}
      <Container
        key="publicationStream-home"
        size="publicationSize"
        padding="Home"
        spacing={spacing}
      >
        <Grid container spacing={spacing}>
          <Grid item xs={12} sm={8}>
            {/* @ts-expect-error ts-migrate(2554) FIXME: Expected 1-2 arguments, but got 0. */}
            {!storiesCurrent && renderPlaceHolderList()}

            <Grid container spacing={4}>
              {!!storiesCurrent &&
                storiesCurrent.slice(0, 10).map((story: any, index: any) => {
                  const {
                    title: titlePost,
                    subtitle,
                    featureImg,
                    User,
                    Publication,
                    publishDate,
                    readingTime,
                  } = story;
                  const withImage = true;
                  return index === 3 ? (
                    <Fragment key={`fragment-grid-row-home-adsense-top-feed-${story.uid}`}>
                      <Grid
                        className={classes.inFeedAdsense}
                        key={`grid-row-home-adsense-top-feed-${story.uid}`}
                        item
                        xs={12}
                      >
                        <Adsense
                          data-ad-layout-key="-gp-11+2i-8r+g2"
                          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ "data-ad-layout-key": string; format: stri... Remove this comment to see the full error message
                          format="fluid"
                          key={`${location.key}-adsense-home-infeed`}
                          responsive="true"
                          client="ca-pub-9169771209644523"
                          slot="8664589439"
                        />
                      </Grid>
                      <Grid key={`grid-row-home-${story.uid}`} item xs={12}>
                        <PublicationStream
                          authorPosition="top"
                          post={story}
                          image={getImage(featureImg, 'focal_280x240')}
                          imageWEBP={getImage(featureImg, 'webp_focal_280x240')}
                          withImage={withImage}
                          title={titlePost}
                          subtitle={subtitle}
                          author={User}
                          publication={Publication}
                          baseDate={baseDate}
                          date={publishDate}
                          readTime={Math.ceil(readingTime / 1000 / 60)}
                          imageLink={getLink(story)}
                          titleLink={getLink(story)}
                        />
                      </Grid>
                    </Fragment>
                  ) : (
                    <Grid key={`grid-row-home-${story.uid}`} item xs={12}>
                      <PublicationStream
                        authorPosition="top"
                        post={story}
                        image={getImage(featureImg, 'focal_280x240')}
                        imageWEBP={getImage(featureImg, 'webp_focal_280x240')}
                        withImage={withImage}
                        title={titlePost}
                        subtitle={subtitle}
                        author={User}
                        publication={Publication}
                        baseDate={baseDate}
                        date={publishDate}
                        readTime={Math.ceil(readingTime / 1000 / 60)}
                        imageLink={getLink(story)}
                        titleLink={getLink(story)}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Sidebar
              popular={popular}
              intl={intl}
              classes={classes}
              dateFns={dateFns}
              location={location}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid container spacing={4}>
              {!!storiesCurrent &&
                storiesCurrent.slice(10).map((story: any, index: any) => {
                  const {
                    title: titlePost,
                    subtitle,
                    featureImg,
                    User,
                    Publication,
                    publishDate,
                    readingTime,
                  } = story;
                  const withImage = true;
                  return index === 3 || (index - 3) % 6 === 0 ? (
                    <Fragment key={`fragment-grid-row-home-${story.uid}`}>
                      <Grid
                        className={classes.inFeedAdsense}
                        key={`grid-row-home-adsense-top-feed-${story.uid}`}
                        item
                        xs={12}
                      >
                        <Adsense
                          data-ad-layout-key="-gp-11+2i-8r+g2"
                          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ "data-ad-layout-key": string; format: stri... Remove this comment to see the full error message
                          format="fluid"
                          key={`${location.key}-adsense-home-infeed`}
                          responsive="true"
                          client="ca-pub-9169771209644523"
                          slot="8664589439"
                        />
                      </Grid>
                      <Grid key={`grid-row-home-${story.uid}`} item xs={12}>
                        <PublicationStream
                          authorPosition="top"
                          post={story}
                          image={getImage(featureImg, 'focal_280x240')}
                          imageWEBP={getImage(featureImg, 'webp_focal_280x240')}
                          withImage={withImage}
                          title={titlePost}
                          subtitle={subtitle}
                          author={User}
                          publication={Publication}
                          baseDate={baseDate}
                          date={publishDate}
                          readTime={Math.ceil(readingTime / 1000 / 60)}
                          imageLink={getLink(story)}
                          titleLink={getLink(story)}
                        />
                      </Grid>
                    </Fragment>
                  ) : (
                    <Grid key={`grid-row-home-${story.uid}`} item xs={12}>
                      <PublicationStream
                        authorPosition="top"
                        post={story}
                        image={getImage(featureImg, 'focal_280x240')}
                        imageWEBP={getImage(featureImg, 'webp_focal_280x240')}
                        withImage={withImage}
                        title={titlePost}
                        subtitle={subtitle}
                        author={User}
                        baseDate={baseDate}
                        publication={Publication}
                        date={publishDate}
                        readTime={Math.ceil(readingTime / 1000 / 60)}
                        imageLink={getLink(story)}
                        titleLink={getLink(story)}
                      />
                    </Grid>
                  );
                })}
              {hasMore && (
                <VisibilitySensor partialVisibility>
                  {({ isVisible, sensorRef }) => {
                    if (isVisible && !loading) {
                      loadMore();
                    }
                    return (
                      <Grid key="grid-row-home-loader" item xs={12}>
                        <div
                          ref={sensorRef}
                          key="stories-loader-0"
                          className={!isVisible ? classes.noVisible : classes.placeHolderLoader}
                        >
                          {renderPlaceHolderList(1)}
                        </div>
                      </Grid>
                    );
                  }}
                </VisibilitySensor>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4} />
        </Grid>
      </Container>
    </>
  );
};

export default Home;
