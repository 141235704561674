// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@mat... Remove this comment to see the full error message
import createSvgIcon from '@mui/icons-material/utils/createSvgIcon';

export default (props: any) => {
  const Icon = createSvgIcon(
    <path
      d="M7.6 5.138L12.03 9.5 7.6 13.862l-.554-.554L10.854 9.5 7.046 5.692"
      fillRule="evenodd"
    />,
    'ArrowDropRight'
  );
  return <Icon {...props} viewBox="0 0 19 19" />;
};
